<template>
  <div style="padding:16px">
    <modalSM v-if="contentID=='shuoming'"/>
    <modalXY v-if="contentID=='xieyi'"/>
    <modalZC v-if="contentID=='zhuce'"/>
  </div>
</template>

<script>
import utils from '@/common/js/utils';
import modalSM from '../publish/shuoming-data-cpt/data-shuoming.vue'
import modalXY from '../publish/shuoming-data-cpt/data-xieyi';
import modalZC from '../publish/shuoming-data-cpt/data-zhuce';

export default {
    data(){
        return {
            contentID:utils.getRequestParams().contentID
        }
    },
    components:{
        modalSM,
        modalXY,
        modalZC,
    },
    created(){
      // alert(utils.getRequestParams().contentID)
    }
}
</script>

<style>

</style>